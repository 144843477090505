import React, { useState } from "react";
import folderIcon from "../../Asssets/Images/folder-alt.svg";
import { apiCallPost } from "../../AxiosApi";
import { API_URL } from "../../utils/ApiUrl";
import { constants } from "../../utils/constant";
import { getFileTypeIcon } from "../../utils/getFileTypeIcon";
import { capitalizeFirstLetter, fileExtension } from "../../utils/helper";
import CardActionDots from "./CardActionDots";
import FilePreviewModal from "./FilePreviewModal";

const GridList = ({
    name,
    id,
    getAllFiles,
    handleImageClick,
    type,
    handleClick,
    icon,
    bucketKey,
    date
    
}) => {
    const [showModal, setShowModal] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);

    const handleItemClick = async (e) => {
        if (type === "folder") {
            handleImageClick();
        } else if (type === "file") {
            e.preventDefault();
            e.stopPropagation();

            // Get file extension
            const extension = fileExtension(name);

            // Only proceed if file is previewable
            if ([...constants.PREVIEWABLE_EXTENSIONS, "pdf"].includes(extension)) {
                try {
                    const response = await apiCallPost(
                        API_URL.DOWNLOAD_FILE,
                        {
                            id,
                        },
                        { responseType: "blob" }
                    );

                    setFileUrl(response.data?.file);
                    setShowModal(true);
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    const handleCloseModal = () => {
        setFileUrl(null);
        setShowModal(false);
    };

    return (
        <>
            <div className="grid_list_card_outer">
                <div
                    className={`view_second_box  ${type === "folder" ? "folder_box" : ""}`}
                >
                    <div onClick={handleItemClick} className="view_second_box_top">
                        <div className="view_second_box_image">
                            <img
                                src={
                                    type === "file" &&
                                        constants.PREVIEWABLE_EXTENSIONS?.includes(
                                            fileExtension(bucketKey)
                                        )
                                        ? process.env.REACT_APP_BUCKET_IMAGE_URL + bucketKey
                                        : type == "file"
                                            ? getFileTypeIcon(name)
                                            : icon
                                                ? icon
                                                : folderIcon
                                }
                                alt=""
                            />
                        </div>
                        <div className="view_second_box_des">
                            {name || "item.name"}{" "}
                            {type == constants.FILE_TYPE.FOLDER &&
                                " (" + capitalizeFirstLetter(type) + ")"}
                        </div>
                    </div>
                    <CardActionDots
                        showDownloadButton={type !== "folder"}
                        icon={icon}
                        name={name}
                        getAllFiles={getAllFiles}
                        id={id}
                        type={type}
                        date={date}
                    />
                </div>
            </div>

            <FilePreviewModal
                show={showModal}
                onHide={handleCloseModal}
                fileUrl={fileUrl}
                fileName={name}
            />
        </>
    );
};

export default GridList;
