import React from 'react'
import { useSearchParams } from 'react-router-dom';

const PageNotFound = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    // console.log('token :>> ', token);
    return (
        <div>
            <h1>Page Not Found</h1>
        </div>
    )
}

export default PageNotFound
