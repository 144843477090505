import { Toaster } from 'react-hot-toast';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import './App.css';
import Dashlite from './components/Dashlite/Dashlite';
import Header from './components/Header/Header';
import Loader from './components/common/Loader/Loader';
import AuthenticationFailed from './components/pages/AuthenticationFailed/AuthenticationFailed';
import LogoutUser from './components/pages/LogoutUser/LogoutUser';
import AuthGaurd from './gaurds/AuthGaurd';
import Authenticate from './gaurds/Authenticate';
import { ROUTES } from './utils/constant';
import PageNotFound from './components/pages/PageNotFound/PageNotFound';

function App() {
	const router = createBrowserRouter([
		{
			path: ROUTES.BASE,
			element: <AuthGaurd>
				<Header />
				<Dashlite />
			</AuthGaurd>,
		},
		{
			path: ROUTES.AUTHENTICATION,
			element: <Authenticate />,
		},
		{
			path: ROUTES.AUTHENTICATION_FAILED,
			element: <AuthenticationFailed />,
		},
		{
			path: ROUTES.LOGGED_OUT,
			element: <LogoutUser />,
		},
		{
			path: "*",
			element: <PageNotFound />,
		},
	]);

	return (
		<>
			<Toaster position="bottom-right" />
			<Loader />
			<RouterProvider router={router} />
		</>
	);
}

export default App;
