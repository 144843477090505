import Swal from "sweetalert2";
import { API_URL } from "../../utils/ApiUrl";
import { apiCallPost } from "../../AxiosApi";
import toast from "react-hot-toast";

export const handleCreateFolder = (rocketShipId, currentFolderKey, parentId, getAllFiles,) => {
    let iconUrl = null;

    const handleSave = async (data, text, iconUrl) => {
        const payload = {
            name: text,
            bucketKey: data.results.key,
            rocketShipId: parentId || String(rocketShipId),
            type: "folder",
            fileType: "folder",
            parentId: parentId,
            label: 'DEFAULT',
            channel: null,
            isDeleted: "false",
            isFavorite: "false",
            icon: iconUrl || null,
        };
        try {
            const response = await apiCallPost(API_URL.FILE_SAVE, payload);
            if (!response.error) {
                getAllFiles();
                toast.success(response.message || "");
            }
        } catch (error) {
            console.error(error);
        }
    };

    
    Swal.fire({
        title: "Add folder",
        html: `
            
            <div class="image-upload-container" style="margin: 20px auto; text-align: center;">
                <div class="image-preview" style="width: 100px; height: 100px; border-radius: 50%; border: 2px dashed #ccc; margin: 0 auto; overflow: hidden; display: flex; align-items: center; justify-content: center; cursor: pointer; background-size: cover; background-position: center;">
                    <i class="fas fa-camera" style="font-size: 24px; color: #666;"></i>
                </div>
                <input type="file" id="folderImage" data-key="" accept="image/*" style="display: none;">
                <div style="margin-top: 10px; font-size: 12px; color: #666;">Click to upload folder icon</div>
            </div>
            <input type="text" id="folderName" class="swal2-input" placeholder="Folder Name">
        `,
        confirmButtonText: "Create",
        showCancelButton: true,
        focusConfirm: false,
        didOpen: () => {
            const folderImageInput = document.getElementById("folderImage");
            const imagePreview = document.querySelector(".image-preview");
            const imageContainer = document.querySelector(".image-upload-container");

            // Click on preview div to trigger file input
            imagePreview.addEventListener("click", () => {
                folderImageInput.click();
            });

            folderImageInput.addEventListener("change", async (event) => {
                const file = event.target.files[0];
                if (file) {
                    // Display preview
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        imagePreview.innerHTML = ""; // Clear the camera icon
                        imagePreview.style.backgroundImage = `url('${e.target.result}')`;
                    };
                    reader.readAsDataURL(file);

                    // Upload image
                    const formData = new FormData();
                    formData.append("file", file);
                    try {
                        
                        const uploadResponse = await apiCallPost(API_URL.UPLOAD_ICON, formData);
                        if (uploadResponse.error) {
                            toast.error("Image upload failed.");
                            imagePreview.style.backgroundImage = "none";
                            imagePreview.innerHTML = '<i class="fas fa-camera" style="font-size: 24px; color: #666;"></i>';
                        } else {
                            toast.success("Image uploaded successfully!");
                            iconUrl = uploadResponse.data.url;
                        }
                    } catch (uploadError) {
                        console.error("Image upload error:", uploadError);
                        toast.error("Error uploading image.");
                    }
                }
            });
        },
        preConfirm: () => {
            const folderName = document.getElementById("folderName").value;
            if (!folderName) {
                Swal.showValidationMessage("You need to write a folder name!");
            }

            return { folderName };
        },
    }).then(async (data) => {
        if (data.isConfirmed) {
            const { folderName } = data.value;

            // Prepare payload
            let payload = {
                folderName: folderName,
                currentDirectoryKey: currentFolderKey || String(rocketShipId),
            };

            try {
                // Handle folder creation API call
                const response = await apiCallPost(API_URL.FOLDER, payload);

                if (!response.error) {
                    handleSave(response.data, folderName, iconUrl);
                    iconUrl = null;
                }
            } catch (error) {
                console.error("Error creating folder:", error);
            }
        }
    });
    
}