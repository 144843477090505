export const ENVIRONMENT = {
  BASE_URL: process.env.REACT_APP_API_HOST,
};

export const ROUTES = {
  AUTHENTICATION: "/authenticate",
  AUTHENTICATION_FAILED: "/authentication-failed",
  LOGGED_OUT: "/logged-out",
  BASE: "/",
};

export const constants = Object.freeze({
  FILE_TYPE: {
    FOLDER: "folder",
    FILE: "file",
  },
  PREVIEWABLE_EXTENSIONS: ["png", "svg", "jpeg", "gif", "jpg"],
});
