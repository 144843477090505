import excelIcon from "../Asssets/Images/excel.svg"
import pptIcon from "../Asssets/Images/file-type-doc-alt.svg"
import docIcon from "../Asssets/Images/file-type-doc.svg"
import imageIcon from "../Asssets/Images/file-type-media-alt.svg"
import videoIcon from "../Asssets/Images/file-type-movie-alt.svg"
import pdfIcon from "../Asssets/Images/file-type-pdf-alt.svg"
import zipIcon from "../Asssets/Images/file-type-zip-alt.svg"
import { getFileType } from "./fileTypeHelper"

export const getFileTypeIcon = (name = "") => {
    switch (getFileType(name)) {
        case "IMAGE":
            return imageIcon;
        case "VIDEO":
            return videoIcon;
        case "PDF":
            return pdfIcon;
        case "WORD_DOCUMENT":
            return docIcon;
        case "EXCEL_SPREADSHEET":
            return excelIcon;
        case "POWERPOINT_PRESENTATION":
            return pptIcon;
        case "ZIP_ARCHIVE":
            return zipIcon;
        default:
            return imageIcon;
    }
}