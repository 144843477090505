import React from 'react';
import GridCard from './GridCard';
import GridList from './GridList';
import ListCard from './ListCard';

const ViewType = ({ viewType, getAllFiles, handleFolderClick, files }) => {
    return (
        <>
            {
                viewType === "grid" ?
                    <>
                        <div className="box">
                            {
                                files &&
                                files?.map((item) =>
                                    <GridCard
                                        handleImageClick={() => handleFolderClick(item)}
                                        id={item.id}
                                        getAllFiles={getAllFiles}
                                        key={item.id}
                                        name={item.name}
                                        icon={item.icon}
                                        type={item.fileType}
                                        bucketKey={item.bucketKey}
                                        date={item.createdAt}
                                    />
                                )
                            }
                        </div>
                    </>
                    :
                    viewType === "grid-list" ?
                        <div className='view_second_inner'>
                            {
                                files &&
                                files?.map((item) =>
                                    <GridList
                                        handleImageClick={() => handleFolderClick(item)}
                                        id={item.id}
                                        getAllFiles={getAllFiles}
                                        key={item.id}
                                        type={item.fileType}
                                        name={item.name}
                                        icon={item.icon}
                                        bucketKey={item.bucketKey}
                                        date={item.createdAt}
                                    />
                                )
                            }
                        </div>
                        :
                        <div className="view_third">
                            <div className="files_list header">
                                <div className="heading_top">
                                    <div className="text-bold files_list_inner heading_top_first">
                                        <span className='ms-4'>Name</span>
                                    </div>
                                    <div className="text-bold heading_top_second">
                                        Type
                                    </div>
                                    <div className="text-bold heading_top_second">
                                        Label
                                    </div>
                                    <div className="text-bold heading_top_second">
                                        Channel
                                    </div>
                                    <div className="text-bold heading_top_second">
                                        Date
                                    </div>
                                    <div className="ms-auto">
                                        Actions
                                    </div>
                                </div>
                            </div>
                            {
                                files &&
                                files?.map((item, index) =>
                                    <ListCard
                                        type={item.fileType}
                                        id={item.id}
                                        date={item.createdAt}
                                        icon={item.icon}
                                        getAllFiles={getAllFiles}
                                        handleImageClick={() => handleFolderClick(item)}
                                        key={item.id}
                                        name={item.name}
                                        channel={item.channel}
                                        label={item.label}
                                        bucketKey={item.bucketKey}
                                        index={index}
                                    />
                                )
                            }
                        </div>
            }
        </>
    )
}

export default ViewType
