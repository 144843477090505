import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { apiCallPost } from "../../AxiosApi";
import { API_URL } from "../../utils/ApiUrl";
import useFileFolder from "../../hooks/useFileFolder";

export const AddFolderModal = (props) => {
	const { onHide, ids } = props;
	const { currentFolderKey, rocketShipId, parentId } = ids
	const { getAllFiles } = useFileFolder();

	const [text, setText] = useState("");
	const handleSave = async (data) => {
		const payload = {
			name: text,
			bucketKey: data.results.key,
			rocketShipId: rocketShipId,
			type: "folder",
			fileType: "folder",
			parentId: parentId,
			label: 'DEFAULT',
			channel: null,
			isDeleted: "false",
			isFavorite: "false",
		};
		try {
			const response = await apiCallPost(API_URL.FILE_SAVE, payload);
			if (!response.error) {
				getAllFiles()
				onHide()
			}

		} catch (error) {
			console.error(error);
		}
	};
	const handleClick = async () => {
		let payload = {
			folderName: text,
			currentDirectoryKey: currentFolderKey
		};
		try {
			const response = await apiCallPost(API_URL.FOLDER, payload);
			if (!response.error) {
				handleSave(response.data)
			}
		} catch (error) {
		}
	};
	return (
		<div>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<Form.Label htmlFor="text">Add folder</Form.Label>
					<Form.Control
						onChange={(e) => setText(e.target.value)}
						type="text"
						id="text"

						aria-describedby=""
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClick}>Create folder</Button>

					<Button onClick={onHide}>Close</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
