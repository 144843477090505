import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { IoCloudUpload } from "react-icons/io5";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { CreateIcon, UploadIcon } from "../../Asssets/icons/icons";
import { apiCallGet } from "../../AxiosApi";
import { API_URL } from "../../utils/ApiUrl";
import "../NextPage/NextPage.css";
import UploadBtnModal from "../UploadBtnModal/UploadBtnModal";
import Button from "../common/Button/Button";
import { AddFolderModal } from "../modal/AddFolderModal";
import "./Headerbar.scss";
import { handleCreateFolder } from "./handleCreateFolder";

const HeaderBar = (props) => {
	const { user } = useSelector(state => state.user);
	const { rocketShipId: storredRockedShipId } = useSelector(state => state.rocketShipId);
	const [show, setShow] = useState(false);
	const [showFolder, setShowFolder] = useState(false);
	const handleClose = () => setShow(false);
	const handleFolderClose = () => setShowFolder(false);
	const { currentFolderKey, rocketShipId, parentId } = props;


	const [rocketShips, setRocketShips] = useState([]);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await apiCallGet(API_URL.GET_ROCKETSHIPS);
				if (response?.data) {
					const data = response.data.map(item => ({
						label: item.name,
						value: item.id
					}));
					setRocketShips(data);
				}
			} catch (error) {
				console.error("Error fetching rocket ships:", error);
			}
		}
		fetchData();
	}, []);

	const handleChange = (selectedOption) => {
		// Handle selection change
		props.handleChangeRocketShipId(selectedOption.value)
		// props.getAllFiles();
	};
	return (
		<>
			<div className="meainbar">
				<div className="meainbar_inner">
					{user.role_id === 1 && <div className="d-flex align-items-center text-start">
						<label htmlFor="cars" className="me-2">Switch RocketShip:</label>
						<ReactSelect
							id="rocketShips"
							className={"rocketShips_select"}
							options={rocketShips}
							classNamePrefix={"select"}
							defaultValue={rocketShips[0]}
							value={rocketShips.filter(item => item.value === storredRockedShipId)[0]}
							onChange={handleChange}
						/>
					</div>}
					{
						user.role_id !== 1 &&
						<h3>
							{
								rocketShips.filter(item => item.value === storredRockedShipId)[0] &&
								rocketShips.filter(item => item.value === storredRockedShipId)[0].label
							}
						</h3>
					}
					<ul className="ms-auto m-0 p-0 meainbar_btns">
						<li>
							<Dropdown className="meainbar_btns_dropdowon">
								<Dropdown.Toggle>
									<GoPlus />
									<span>Create</span>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Button onClick={() => setShow(!show)} className={"gray_btn"}>
										<UploadIcon /> Upload Folder
									</Button>
									<Button className={"gray_btn"}
										onClick={() => handleCreateFolder(rocketShipId, currentFolderKey, parentId, props.getAllFiles)}>
										<CreateIcon /> Create Folder
									</Button>
								</Dropdown.Menu>
							</Dropdown>
						</li>
						<li>
							<Button onClick={() => setShow(!show)}>
								<IoCloudUpload />
								<span>Upload</span>
							</Button>
						</li>
					</ul>
				</div>
			</div>
			{showFolder && <AddFolderModal ids={props} show={showFolder} onHide={handleFolderClose} />}
			{show && <UploadBtnModal showAllFiles={props.getAllFiles} ids={props} show={show} handleClose={handleClose} />}
		</>
	);
};

export default HeaderBar;
