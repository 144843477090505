import axios from "axios";
import { store as storeInstance } from "../redux/store";
import { ENVIRONMENT } from "../utils/constant";
import { setLoading } from "../reducer/loading.slice";
//@ts-ignore

const axiosApi = axios.create({
  baseURL: ENVIRONMENT.BASE_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (config?.headers?.noAuth === "true") {
      config.headers["api-access-token"] = `${token}`;
    } else if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function handleSuccess(res) {
  if (res?.status === 200 || res?.status === 201) {
    // res?.message && toasts?.success(res?.message);
    // res?.data?.message && toasts?.success(res?.data?.message);
  }
  if (res.status === 403 || res.status === 400) {
    // res.message && toasts.warning(res.message);
  }
}

async function apiCallGet(
  url,
  header,
  toastOn = false,
  loader = true
) {
  loader && storeInstance.dispatch(setLoading(true));
  try {
    const response = await axiosApi.get(url, header);
    const decryptData = response?.data;

    if (toastOn) {
      handleSuccess(decryptData);
    }

    loader && storeInstance.dispatch(setLoading(false));
    return decryptData;
  } catch (error) {
    const decryptData = error?.response?.data;
    loader && storeInstance.dispatch(setLoading(false));
    if (error?.response?.status === 401) {
      // toasts.error('Please re-login, last login session expired.')
      localStorage.removeItem("token");
      // window.locati///////////on.reload()
    }
    if (
      (decryptData && decryptData?.message) ||
      decryptData?.message === "Network Error"
    ) {
      //   toastOn && toasts.error(decryptData?.message);
    }
    return decryptData;
  }
}

async function apiCallPost(
  url,
  data,
  toastOn = true,
  loader = true
) {
  loader && storeInstance.dispatch(setLoading(true));
  const dataFiltered = data;

  try {
    const resp = await axiosApi.post(url, dataFiltered, {});
    const decryptData = resp?.data;

    if (toastOn === true) {
      handleSuccess(decryptData);
    }

    loader && storeInstance.dispatch(setLoading(false));
    return decryptData;
  } catch (error) {
    const decryptData = error?.response?.data;
    loader && storeInstance.dispatch(setLoading(false));

    if (error?.response?.status && error?.response?.status === 401) {
      // toasts.error("Please re-login, last login session expired.");
      localStorage.removeItem("token");
      window.location.reload();
    }

    if (decryptData?.message || decryptData?.message === "Network Error") {
      //   toastOn && toasts.error(decryptData?.message);
    }

    return decryptData;
  }
}

// async function apiCallPut<T, R>(
//     { url,
//         data,
//         toastOn,
//         loader = false }: ApiRequest<T>
// ): Promise<T> {
//     let dataFiltered = data;
//     //   loader && storeInstance.dispatch(setLoader(true));
//     const resp = await axiosApi
//         .put(url, dataFiltered)
//         .then(async (res) => {
//             let decryptData = res?.data

//             if (toastOn === true) {
//                 handleSuccess(decryptData);
//             }
//             //   loader && storeInstance.dispatch(setLoader(false));
//             return decryptData;
//         })
//         .catch((error) => {
//             //   loader && storeInstance.dispatch(setLoader(false));
//             let decryptData = error?.response?.data
//             if (error?.response?.status && error?.response?.status === 401) {
//                 // toasts.error("Please re-login, last login session expired.");
//                 localStorage.clear();
//             }
//             if (
//                 (decryptData && decryptData?.message) ||
//                 decryptData?.message === "Network Error"
//             ) {
//                 // toasts.error(decryptData?.message);
//             }
//             return decryptData;
//         });

//     return resp;
// };

// async function apiCallPatch<T, R>(
//     { url,
//         data,
//         toastOn,
//         loader = false }: ApiRequest<T>
// ) {
//     let dataFiltered = data
//     //   loader && storeInstance.dispatch(setLoader(true));
//     const resp = await axiosApi
//         .patch(url, dataFiltered)
//         .then(async (res) => {
//             let decryptData = res?.data

//             if (toastOn === true) {
//                 handleSuccess<R>(decryptData);
//             }
//             //   loader && storeInstance.dispatch(setLoader(false));
//             return decryptData;
//         })
//         .catch((error) => {
//             //   loader && storeInstance.dispatch(setLoader(false));
//             let decryptData = error?.response?.data
//             if (error?.response?.status && error?.response?.status === 401) {
//                 // toasts.error("Please re-login, last login session expired.");
//                 localStorage.clear();
//             }
//             if (
//                 (decryptData && decryptData?.message) ||
//                 decryptData?.message === "Network Error"
//             ) {
//                 // toasts.error(decryptData?.message);
//             }
//             return decryptData;
//         });

//     return resp;
// };

// const apiCallDelete = (url: string, toastOn: boolean) => {
//     return new Promise((resolve, reject) => {
//         axiosApi
//             .delete(url)
//             .then((res) => {
//                 if (toastOn === true) {
//                     handleSuccess(res);
//                 }
//                 resolve(res.data);
//             })
//             .catch((error) => {
//                 handleError(error);
//                 reject(error);
//             });
//     });
// };

export {
  apiCallGet,
  apiCallPost,
  // apiCallPut,
  // apiCallPatch,
  // apiCallDelete
};
