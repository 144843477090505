import React from 'react'
import "./ViewDetailsModal.scss";
import { Modal } from 'react-bootstrap'
import Button from '../../common/Button/Button'
import { capitalizeFirstLetter } from '../../../utils/helper';

const ViewDetailsModal = ({ show, handleClose, type, date, handleDownload }) => {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                className="view_details_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>File Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li>
                            <h6>Type</h6>
                            <p>{capitalizeFirstLetter(type)}</p>
                        </li>
                        <li>
                            <h6>Modified</h6>
                            <p>By Rocketship Team</p>
                        </li>
                        <li>
                            <h6>Created</h6>
                            <p>{new Date(date).toLocaleString()}</p>
                        </li>
                    </ul>
                    <div className="view_details_footer d-flex mt-4 align-items-center">
                        {type !== "folder" && <Button onClick={handleDownload} fluid={true}>Download</Button>}
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ViewDetailsModal
