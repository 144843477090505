
/*******************To Capitalize First Letter*************/
export function capitalizeFirstLetter(str) {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export const fileExtension = (name) => name?.split(".").pop().toLowerCase();
