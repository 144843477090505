import { useState } from 'react';
import { apiCallGet, apiCallPost } from '../AxiosApi';
import { useDispatch } from 'react-redux';
import { setLoading } from '../reducer/loading.slice';
const getFolder = (data, type) => {
	return data.filter((item) => item.fileType === type)
}
const useFileFolder = () => {
	const [folder, setFolder] = useState([]);
	const [files, setFiles] = useState([]);
	const [allFiles, setAllFiles] = useState([]);
	const dispatch = useDispatch();


	const getFilteredData = async (api, { label, channel, orderBy, search }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiCallPost(api, {
				channel: channel || "",
				label: label || "",
				sort: orderBy || "",
				search: search || "",
			});
			if (response) {
				if (!response.error) {
					// setFolder(getFolder(response.data, "folder"));
					// setFiles(getFolder(response.data, "file"));

					response.data.data.sort((a, b) => {

						if (a.fileType === 'file' && b.fileType === 'folder') return 1;

						if (a.fileType === 'folder' && b.fileType === 'file') return -1;

						return 0;
					});

					setAllFiles(response.data.data);
				}
			}
		} catch (error) {
			console.error(error);
			dispatch(setLoading(false));
		}
	}
	const getAllFiles = async (api, { label, channel, search, orderBy }) => {
		dispatch(setLoading(true));
		if (label || channel || search || orderBy) {
			getFilteredData(api, { label, channel, search, orderBy });
		} else {
			try {
				dispatch(setLoading(true));
				const response = await apiCallGet(api);
				console.log({response}); 
				if (response) {
					if (!response.error) {
						setFolder(getFolder(response.data, "folder"));
						setFiles(getFolder(response.data, "file"));

						response.data.sort((a, b) => {

							if (a.fileType === 'file' && b.fileType === 'folder') return 1;

							if (a.fileType === 'folder' && b.fileType === 'file') return -1;

							return 0;
						});

						setAllFiles(response.data);
					}
				}
				dispatch(setLoading(false));
			} catch (error) {
				console.error(error);
				dispatch(setLoading(false));
			}
		}

	};
	return { folder, allFiles, files, getAllFiles };
};

export default useFileFolder;
