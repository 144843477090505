import React, { useState } from "react";
import folderIcon from "../../Asssets/Images/folder-alt.svg";
import { apiCallPost } from "../../AxiosApi";
import { API_URL } from "../../utils/ApiUrl";
import { constants } from "../../utils/constant";
import { getFileTypeIcon } from "../../utils/getFileTypeIcon";
import { capitalizeFirstLetter, fileExtension } from "../../utils/helper";
import CardActionDots from "./CardActionDots";
import FilePreviewModal from "./FilePreviewModal";

const GridCard = ({
    handleImageClick,
    getAllFiles,
    type,
    icon,
    name,
    id,
    bucketKey,
    date
}) => {
    const [showModal, setShowModal] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);

    const handleCardImageClick = async (e) => {
        if (type === "folder") {
            handleImageClick();
        } else if (type === "file") {
            e.preventDefault();
            e.stopPropagation();

            // Get file extension
            const extension = fileExtension(name)

            // Only proceed if file is previewable
            if (
                [...constants.PREVIEWABLE_EXTENSIONS, "pdf"].includes(extension)
            ) {
                try {
                    const response = await apiCallPost(
                        API_URL.DOWNLOAD_FILE,
                        {
                            id,
                        },
                        { responseType: "blob" }
                    );

                    setFileUrl(response.data?.file);
                    setShowModal(true);
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    const handleCloseModal = () => {
        setFileUrl(null);
        setShowModal(false);
    };

    return (
        <>
            <div className="grid_card_box_outer">
                <div className={`box_inner ${type === "folder" ? "folder_box" : ""}`}>
                    <div
                        onClick={handleCardImageClick}
                        className={`box_img ${type === "file" ? "clickable" : ""}`}
                    >
                        <img
                            src={
                                type === "file" &&
                                    constants.PREVIEWABLE_EXTENSIONS?.includes(
                                        fileExtension(bucketKey)
                                    )
                                    ? process.env.REACT_APP_BUCKET_IMAGE_URL + bucketKey
                                    : type == "file"
                                        ? getFileTypeIcon(name)
                                        : icon
                                            ? icon
                                            : folderIcon
                            }
                            alt=""
                        />
                    </div>
                    <h3 onClick={handleCardImageClick}>
                        {name}{" "}
                        {type == constants.FILE_TYPE.FOLDER &&
                            " (" + capitalizeFirstLetter(type) + ")"}
                    </h3>
                    <CardActionDots
                        showDownloadButton={type !== "folder"}
                        icon={icon}
                        getAllFiles={getAllFiles}
                        name={name}
                        id={id}
                        type={type}
                        date={date}
                    />
                </div>
            </div>

            <FilePreviewModal
                show={showModal}
                onHide={handleCloseModal}
                fileUrl={fileUrl}
                fileName={name}
            />
        </>
    );
};

export default GridCard;
