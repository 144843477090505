import { useState } from 'react';
import { apiCallGet } from '../AxiosApi';
import { API_URL } from '../utils/ApiUrl';

const getFolder = (data, type) => {
  return data.filter((item) => item.fileType === type)
}

const useUserFavoriteFolder = () => {
  const [favoriteFolder, setFavoriteFolder] = useState([]);
  const [favoriteFiles, setFavoriteFiles] = useState([]);

  const getAllFavorite = async (rocketShipId) => {
    try {
      const response = await apiCallGet(API_URL.GET_FAVORITE_FILES + '/' + rocketShipId);
      if (!response.error) {
        setFavoriteFolder(getFolder(response.data, "folder"));
        setFavoriteFiles(getFolder(response.data, "file"));
      }
    } catch (error) {
      console.error(error);
    } 
  };

  return { favoriteFolder, favoriteFiles, getAllFavorite };
};

export default useUserFavoriteFolder;
