import React from 'react';
import { Crossicon, FolderIcon } from '../../../Asssets/icons/icons';
import "./UploadingFilesDropzone.scss";

const UploadingFilesDropzone = ({ show, handleClose }) => {
    return (
        <>
            <div className="uplaoding_file_dropzone_modal">
                <div className='uplaoding_file_dropzone_modal_header'>
                    <h6>1 Upload Complete</h6>
                    <button>
                        <Crossicon />

                    </button>
                </div>
                <ul>
                    <li>
                        <h4><FolderIcon /> app-file.html <span><Crossicon /></span></h4>
                    </li>
                    <li>
                        <h4><FolderIcon /> app-file.html <span><Crossicon /></span></h4>
                    </li>
                    <li>
                        <h4><FolderIcon /> app-file.html <span><Crossicon /></span></h4>
                    </li>
                    <li>
                        <h4><FolderIcon /> app-file.html <span><Crossicon /></span></h4>
                    </li>
                </ul>
            </div>
        </>

    )
}

export default UploadingFilesDropzone
