import React, { useState } from "react";
import "./UploadBtnModal.css";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import ReactSelect from "react-select";
import file_zip from "../../Asssets/Images/file-type-zip-alt.svg";
import { apiCallPost } from "../../AxiosApi";
import { API_URL } from "../../utils/ApiUrl";
import { channelOpions, labelOptions } from "../Dashlite/Dashlite";
import Button from "../common/Button/Button";
const UploadBtnModal = (props) => {
	const [step, setStep] = useState(1);
	const [channel, setChannel] = useState(channelOpions[0])
	const [label, setLabel] = useState(labelOptions[0])
	const { handleClose, show, ids } = props;
	const { currentFolderKey, parentId, rocketShipId } = ids
	// const { getAllFiles } = useFileFolder();

	const [selectedFile, setSelectedFile] = useState(null);
	// const [imageURL, setImageURL] = useState(null);


	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};
	const handleDragEnter = (event) => {
		event.preventDefault();
		// setDragging(true);
	};

	const handleDragLeave = () => {
		// setDragging(false);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = (event) => {
		event.preventDefault();
		// setDragging(false);
		const file = event.dataTransfer.files[0];
		setSelectedFile(file);
		displayImage(file);
	};
	const displayImage = (file) => {
		const reader = new FileReader();
		reader.onload = () => {
			// setImageURL(reader.result);
		};
		reader.readAsDataURL(file);
	};
	const handleSave = async (data) => {
		const payload = {
			name: props?.selectedFile?.name || selectedFile.name,
			bucketKey: data.Key,
			rocketShipId: parentId || rocketShipId,
			type: "file",
			fileType: "file",
			parentId: parentId,
			label: label.value,
			channel: channel.value,
			isDeleted: "false",
			isFavorite: "false",
		};
		try {
			const response = await apiCallPost(API_URL.FILE_SAVE, payload);
			if (!response.error) {
				// getAllFiles()
				props.showAllFiles();
				handleClose()
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleUpload = async () => {
		if (props?.selectedFile || selectedFile) {
			const formData = new FormData();
			formData.append("files", props?.selectedFile || selectedFile);
			formData.append("name", props?.selectedFile?.name || selectedFile.name);
			formData.append("currentFolderKey", currentFolderKey);
			formData.append("rocketshipId", rocketShipId);

			try {
				const response = await apiCallPost(API_URL.UPLOAD, formData);
				if (!response?.error) {
					response.data.map((item) => {
						return handleSave(item);
					});
					toast.success(response.message)
				}
			} catch (error) {
				console.log(error);
			}
			// You can use Axios or Fetch API to upload the file
			// Replace 'uploadUrl' with your actual endpoint
		}
	};
	const handleNext = () => {
		setStep(2);
	}
	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				centered
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title>Upload File</Modal.Title>
					<div onClick={handleClose}>
						<RxCross2 />
					</div>
				</Modal.Header>
				{
					step === 1 ?
						<Modal.Body>
							{!props?.selectedFile && <div
								onDragEnter={handleDragEnter}
								onDragLeave={handleDragLeave}
								onDragOver={handleDragOver}
								onDrop={handleDrop}
							>
								<label className="upload_btn_modal_input">
									<span>Choose File to Upload</span>
									<input type="file" onChange={handleFileChange} />
								</label>
							</div>}
							<h4 className="mt-3">Uploaded Files</h4>
							{
								(props?.selectedFile || selectedFile) ?
									<div className="uploaded_files">
										<div className="uploaded_files_inner">
											<img src={file_zip} alt="" />
											<div className="uploaded_files_descrip">
												<h5>{props?.selectedFile?.name || selectedFile?.name}</h5>
												{/* <h6>25.49 MB</h6> */}
											</div>
										</div>
									</div>
									:
									<h6>No File Uploaded</h6>
							}
							{/* <div className="uploaded_files">
						<div className="uploaded_files_inner">
							<img src={file_doc} alt="" />
							<div className="uploaded_files_descrip">
								<h5>Update work history.docx</h5>
								<h6>25.49 MB</h6>
							</div>
						</div>
						<div>
							<GoTrash />
						</div>
					</div> */}
						</Modal.Body>
						:
						<Modal.Body>
							<div className="mb-3">
								<label className="mb-2">Channel</label>
								<ReactSelect
									options={channelOpions}
									defaultValue={channelOpions[0]}
									onChange={option => setChannel(option)}
								/>
							</div>
							<div>
								<label className="mb-2">Label</label>
								<ReactSelect
									options={labelOptions}
									defaultValue={labelOptions[0]}
									onChange={option => setLabel(option)}
								/>
							</div>
						</Modal.Body>
				}
				<Modal.Footer>
					<Button onClick={handleClose}>
						Close
					</Button>
					{
						step === 1 ?
							<Button onClick={handleNext} disabled={!(props?.selectedFile || selectedFile)}>
								Next
							</Button>
							:
							<Button onClick={handleUpload} disabled={!(props?.selectedFile || selectedFile)}>
								Upload
							</Button>
					}
				</Modal.Footer>
			</Modal>
		</>
	);
};
export default UploadBtnModal;
