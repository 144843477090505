import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import Swal from 'sweetalert2';
import { DownloadIcon, EyeIcon, PenIcon, TrashIcon } from '../../Asssets/icons/icons';
import { apiCallPost } from '../../AxiosApi';
import { API_URL } from '../../utils/ApiUrl';
import { getFileType } from '../../utils/fileTypeHelper';
import ViewDetailsModal from '../modal/ViewDetailsModal/ViewDetailsModal';

const CardActionDots = ({ getAllFiles, showDownloadButton, name, id, icon, date, type }) => {

    const ref = useRef(null);
    const [showDetails, setShowDetails] = useState(false);
    let iconUrl = null;

    const handleRename = () => {
        Swal.fire({
            title: "Rename",
            html: `
                <div class="image-upload-container" style="margin: 20px auto; text-align: center;">
                    ${(getFileType(name) == "FOLDER") ? `<div class="image-preview" style="width: 100px; height: 100px; border-radius: 50%; border: 2px dashed #ccc; margin: 0 auto; overflow: hidden; display: flex; align-items: center; justify-content: center; cursor: pointer; background-size: cover; background-position: center; ${icon ? `background-image: url('${icon}');` : ''}">
                        ${!icon ? '<i class="fas fa-camera" style="font-size: 24px; color: #666;"></i>' : ''}
                    </div>
                        <input type="file" id="folderImage" accept="image/*" style="display: none;">
                    ` : ``}                    
                    
                    <div style="margin-top: 10px; font-size: 12px; color: #666;">Click to update folder icon</div>
                </div>
                <input type="text" id="folderName" class="swal2-input" value="${name}">
            `,
            showCancelButton: true,
            confirmButtonText: "Update",
            didOpen: () => {
                // Only proceed if this is a folder
                if (getFileType(name) !== "FOLDER") return;

                const folderImageInput = document.getElementById("folderImage");
                const imagePreview = document.querySelector(".image-preview");

                // Click on preview div to trigger file input
                imagePreview.addEventListener("click", () => {
                    folderImageInput.click();
                });

                folderImageInput.addEventListener("change", async (event) => {
                    const file = event.target.files[0];
                    if (file) {
                        // Display preview
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            imagePreview.innerHTML = ""; // Clear the camera icon
                            imagePreview.style.backgroundImage = `url('${e.target.result}')`;
                        };
                        reader.readAsDataURL(file);

                        // Upload image
                        const formData = new FormData();
                        formData.append("file", file);
                        try {
                            const uploadResponse = await apiCallPost(API_URL.UPLOAD_ICON, formData);
                            if (uploadResponse.error) {
                                toast.error("Image upload failed.");
                                imagePreview.style.backgroundImage = icon ? `url('${icon}')` : 'none';
                                imagePreview.innerHTML = !icon ? '<i class="fas fa-camera" style="font-size: 24px; color: #666;"></i>' : '';
                            } else {
                                toast.success("Image uploaded successfully!");
                                iconUrl = uploadResponse.data.url;
                                folderImageInput.dataset.uploadedImageUrl = iconUrl;
                            }
                        } catch (uploadError) {
                            console.error("Image upload error:", uploadError);
                            toast.error("Error uploading image.");
                        }
                    }
                });
            },
            preConfirm: () => {
                const newName = document.getElementById("folderName").value;

                if (!newName) {
                    Swal.showValidationMessage("You need to write a name!");
                    return false;
                }

                if (getFileType(name) !== "FOLDER") {
                    if (getFileType(newName) === "FOLDER") {
                        Swal.showValidationMessage("Invalid File Type");
                        return false;
                    }
                }

                return { newName };
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { newName } = result.value;
                const payload = {
                    id,
                    name: newName,
                    icon: iconUrl || null // Include icon URL in payload if it exists
                };

                try {
                    const response = await apiCallPost(API_URL.FILE_RENAME, payload);
                    if (!response.error) {
                        toast.success(response.message || "");
                        getAllFiles();
                    }
                } catch (error) {
                    console.error(error);
                    toast.error("Failed to update folder");
                }
            }
        });
    };
    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async data => {
            if (data.isConfirmed) {
                const payload = {
                    id,
                    // name: name,
                    key: "uploads/Screenshot from 2024-01-18 15-17-57.png",
                }
                try {
                    const response = await apiCallPost(API_URL.DELETE_FILE, payload);
                    if (!response.error) {
                        toast.success(response.message || "");
                        getAllFiles();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        })
    };
    const handleDownload = async () => {
        try {
            const response = await apiCallPost(API_URL.DOWNLOAD_FILE, {
                id,
            });
            if (!response.error) {
                window.open(response.data.file, "_blank", "noreferrer");
                ref.current && ref.current.click();
                toast.success(response.message || "");
            }
        } catch (error) {
            console.error(error);
        }
        
        setShowDetails(false);
    }

    return (
        <>
            <Dropdown className="dots_icon">
                <Dropdown.Toggle ref={ref}>
                    <HiOutlineDotsHorizontal />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <button onClick={() => setShowDetails(true)}><EyeIcon /> Details</button>
                    <button onClick={handleRename}><PenIcon /> Rename</button>
                    <button onClick={handleDelete}><TrashIcon /> Delete</button>
                    {
                        showDownloadButton &&
                        <button onClick={handleDownload}><DownloadIcon /> Download</button>
                    }
                </Dropdown.Menu>
            </Dropdown>
            <ViewDetailsModal
                show={showDetails}
                handleClose={() => setShowDetails(false)}
                type={type}
                date={date}
                handleDownload={handleDownload}
            />
        </>
    )
}

export default CardActionDots
