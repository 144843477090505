import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: "",
}

export const folderSlice = createSlice({
  name: 'folder',
  initialState,
  reducers: {
   
    setFolders: (state, action) => {
        state.value += action.payload
    },
   
  },
})

// Action creators are generated for each case reducer function
export const {  setFolders } = folderSlice.actions

export default folderSlice.reducer