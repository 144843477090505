function getFileType(filename = '') {
    if (!filename.includes('.')) {
        return 'FOLDER';
    }
    const extension = filename.split('.').pop().toLowerCase();
    const fileTypeMap = {
        'jpeg': 'IMAGE',
        'jpg': 'IMAGE',
        'png': 'IMAGE',
        'gif': 'IMAGE',
        'mp4': 'VIDEO',
        'mov': 'VIDEO',
        'avi': 'VIDEO',
        'pdf': 'PDF',
        'json': "POWERPOINT_PRESENTATION",
        'doc': 'WORD_DOCUMENT',
        'docx': 'WORD_DOCUMENT',
        'xls': 'EXCEL_SPREADSHEET',
        'xlsx': 'EXCEL_SPREADSHEET',
        'ppt': 'POWERPOINT_PRESENTATION',
        'pptx': 'POWERPOINT_PRESENTATION',
        'zip': 'ZIP_ARCHIVE'
    };
    if (extension in fileTypeMap) {
        return fileTypeMap[extension];
    } else {
        return 'FILE';
    }
}
module.exports = {
    getFileType
};
