import React, { useState } from "react";
import folderIcon from "../../Asssets/Images/folder-alt.svg";
import CardActionDots from "./CardActionDots";
import { getFileTypeIcon } from "../../utils/getFileTypeIcon";
import Modal from "react-bootstrap/Modal";
import { apiCallPost } from "../../AxiosApi";
import { API_URL } from "../../utils/ApiUrl";
import { capitalizeFirstLetter, fileExtension } from "../../utils/helper";
import { constants } from "../../utils/constant";
import FilePreviewModal from "./FilePreviewModal";

const ListCard = ({
  handleImageClick,
  getAllFiles,
  icon,
  type,
  name,
  id,
  date,
  bucketKey,
  label,
  channel, 

  }) => {
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleItemClick = async (e) => {
    if (type === "folder") {
      handleImageClick();
    } else if (type === "file") {
      e.preventDefault();
      e.stopPropagation();

      // Get file extension
      const extension = fileExtension(name);

      // Check if it's an icon file or other previewable file
      if (
        [...constants.PREVIEWABLE_EXTENSIONS, "pdf"].includes(extension)
      ) {
        try {
          const response = await apiCallPost(
            API_URL.DOWNLOAD_FILE,
            {
              id,
            },
            { responseType: "blob" }
          );

          setFileType(fileExtension);
          setFileUrl(response.data?.file);
          setShowModal(true);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleCloseModal = () => {
    if (fileUrl) {
      window.URL.revokeObjectURL(fileUrl);
    }
    setShowModal(false);
  };

  return (
    <>
      <div className="files_list">
        <div className={`heading_top ${type === "folder" ? "folder_box" : ""}`}>
          <div className="files_list_inner heading_top_first">
            <input type="checkbox" />
            <div
              onClick={handleItemClick}
              className={`file_list_img ${type === "file" ? "clickable" : ""}`}
            >
              <img
                src={
                  type === "file" &&
                  constants.PREVIEWABLE_EXTENSIONS?.includes(
                    fileExtension(bucketKey)
                  )
                    ? process.env.REACT_APP_BUCKET_IMAGE_URL + bucketKey
                    : type == "file"
                      ? getFileTypeIcon(name)
                      : icon
                        ? icon
                        : folderIcon
                }
                alt=""
              />
            </div>
            <p onClick={handleItemClick} className="mb-0">
              {name}
            </p>
          </div>
          <div className="time heading_top_second">
            {capitalizeFirstLetter(type)}
          </div>
          <div className="time heading_top_second">
            {label}
          </div>
          <div className="time heading_top_second">
            {channel}
          </div>
          <div className="time heading_top_second">
            {new Date(date).toLocaleString()}
          </div>
          <div className="ms-auto">
            <CardActionDots
              icon={icon}
              showDownloadButton={type !== "folder"}
              getAllFiles={getAllFiles}
              name={name}
              id={id}
              type={type}
              date={date}
            />
          </div>
        </div>
      </div>

      <FilePreviewModal
        show={showModal}
        onHide={handleCloseModal}
        fileUrl={fileUrl}
        fileName={name}
      />

    
    </>
  );
};

export default ListCard;
