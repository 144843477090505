import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthGaurd = ({ children }) => {
    const { user } = useSelector(state => state.user);
    if (user) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return <Navigate to="/logged-out" />
    }

}

export default AuthGaurd
