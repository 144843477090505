import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { fileExtension } from '../../utils/helper';

const FilePreviewModal = ({ show, onHide, fileUrl, fileName }) => {
    // Get file extension
    const fileType = fileExtension(fileName);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{fileName}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {fileType === "pdf" ? (
                    <iframe 
                        src={fileUrl} 
                        title={fileName} 
                        style={{ 
                            width: '100%', 
                            height: '70vh', 
                            border: 'none' 
                        }} 
                    />
                ) : (
                    <img 
                        src={fileUrl} 
                        alt={fileName} 
                        style={{ 
                            maxWidth: '100%', 
                            maxHeight: '70vh', 
                            objectFit: 'contain' 
                        }} 
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default FilePreviewModal; 