import React from 'react'
import "././AuthenticationFailed.scss";
import { Col, Container, Row } from 'react-bootstrap';

const AuthenticationFailed = () => {
    return (
        <section className="auth_failed">
            <Container>
                <Row className="justify-content-center">
                    <Col md={12} className='text-center'>
                        {/* <h3>Error 404</h3> */}
                        <h1>Authentication Failed</h1>
                        {/* <p>{error.stack}</p>
                        <Link to={ROUTES.HOME}>Go Home <RightArrow /></Link> */}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AuthenticationFailed
